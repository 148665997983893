import { useEffect, useState } from "react";
import {
  ColumnLeft,
  Content,
  Foot,
  LinkLegal,
  LinksLegais,
  Logo,
  Social,
  Socials,
  TextoLegal,
} from "./styled";
import facebookIcon from "../../assets/images/icons/facebook.svg";
import instagramIcon from "../../assets/images/icons/instagram.svg";
import linkedinIcon from "../../assets/images/icons/linkedin.svg";
import twitterIcon from "../../assets/images/icons/twitter.svg";
import youtubeIcon from "../../assets/images/icons/youtube.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Footer = () => {
  const navigate = useNavigate();
  const [geo, setGeo] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: "",
  });

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setGeo({
          ...geo,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const goHome = (e) => {
    e.preventDefault();
    if (e.ctrlKey || e.button === 1) {
      window.open(`/`, "_blank");
    } else if (e.type === "click") {
      navigate(`/`);
    }
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  useEffect(() => {
    // console.log(geo);
  }, [geo]);

  return (
    <Foot>
      <Content>
        <ColumnLeft>
          <Socials>
            <Social
              target="new"
              href="https://web.facebook.com/paramountbrasil"
            >
              <img src={facebookIcon} alt={"Ícone Facebook"} />
            </Social>
            <Social
              target="new"
              href="https://www.instagram.com/paramountbrasil/"
            >
              <img src={instagramIcon} alt={"Ícone Instagram"} />
            </Social>
            <Social target="new" href="https://twitter.com/ParamountBrasil">
              <img src={twitterIcon} alt={"Ícone Twitter"} />
            </Social>
            <Social
              target="new"
              href="https://www.youtube.com/@ParamountBrasil"
            >
              <img src={youtubeIcon} alt={"Ícone Youtube"} />
            </Social>
          </Socials>
          <LinksLegais>
            <LinkLegal
              target="new"
              href="mailto:contato@paramountpictures.com.br"
            >
              CONTATO
            </LinkLegal>
            <LinkLegal
              target="new"
              href="https://privacy.paramount.com/br/policy"
            >
              POLÍTICA DE PRIVACIDADE
            </LinkLegal>
            <LinkLegal
              target="new"
              href="https://paramountpictures.com.br/termos.pdf"
            >
              TERMOS DE USO
            </LinkLegal>
            {/* <a id="ot-sdk-btn" className="ot-sdk-show-settings">
              NÃO PERMITO A VENDA DAS MINHAS INFORMAÇÕES
            </a> */}
            {geo.countryCode === "+1" && (
              <a
                id="ot-sdk-btn"
                className="ot-sdk-show-settings"
                style={{
                  display: "none",
                  position: "fixed",
                  bottom: "10px",
                  left: "10px",
                  zIndex: "3000001",
                }}
              >
                Your Privacy Choices
              </a>
            )}
          </LinksLegais>
          <TextoLegal>
            © 2025 PARAMOUNT PICTURES. ALL RIGHTS RESERVED.
          </TextoLegal>
        </ColumnLeft>

        <Logo onClick={(e) => goHome(e)} />
      </Content>
    </Foot>
  );
};

export default Footer;
